@media print {
  @page {
    size: 210mm 297mm;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .print-display-none {
    display: none;
  }

  .print-display-unset {
    display: unset !important;
  }

  .print-order-1 {
    order: 1;
  }

  .print-marginbottom-20 {
    margin-bottom: 20px;
  }

  .print-maxheight-65 {
    max-height: 6.5rem !important;
  }

  .print-fontsize-105 {
    font-size: 1.05rem;
  }

  .print-flex-maxwidth-50 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .print-flex-maxwidth-100 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}