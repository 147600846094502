.dot{
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: inline-block;
  }
  
  input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: -3px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border: 1px solid grey !important
}

input:checked + .slider {
  background-color: #e73138;;
}

input:focus + .slider {
  box-shadow: 0 0 1px #e73138;;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
  
.half-circle {
  text-align: center;
  border-radius: 100%;
  background-color: #e2e2e2;
  background: linear-gradient(white, white), linear-gradient(90deg, green 33%, #e2e2e2 0%);
  border: 2px solid transparent;
  background-origin: content-box, border-box !important;
  background-clip: content-box, border-box !important;
  font-size:11px;
  font-variant-position: sub;
  font-weight: bold; 
  }
  
  #btnControl {
    display: none;
}

#btnControl:checked + label > img {
    width: 70px;
    height: 74px;
}
  .startVideoDiv{
      position: relative; 
      height: 0px;
      z-index: 999;
  } 
  
  .doneBtnDiv{
      position: relative; 
      left: 211px; 
      width:0px; 
      top:-220px;
  }  
  
  .doneBtn{
     border: 1px solid #c6c6c6; 
     background-image: linear-gradient(to bottom, #fdfdfd 0%, #F4f4f4 51%, #ebebeb 100%);
     border-radius: 0.4rem;
  }
  
  .articleElem{
    border: 2px solid #c6c6c6;
    border-radius: 0.4rem;
  }
  .cartDiv{
      position: relative;
      top: -35px;
  }
  
.cart{
  height: 60px;
  width: 60px;
  background-color:  #e73138;
  /*background-image: url(/images/cart_w.svg);*/
  background-size: 70% auto;
  background-repeat: no-repeat;
  background-position: center;
}

.tiles{
    border-radius: 0.4rem;
    height: 192px;
    box-shadow: 0px 0px 4px 4px #e2e2e2;
   
}

.subtiles{
    padding-top: 9px;
    z-index: -1;
    position: relative;
    top: -7px;
    left: 4px;
    height: 30px;
    background-color: #f0f0f0; 
}

.tilesDisplayNone
{
    display: none;
}

.tilesDone{
   border: 2px solid #09c200;
   opacity: 0.5;
}
.tilesAlert{
   border: 2px solid #e73138;
}

.lowerTilesDone{
    background-color: #f0f0f0;
}

.uppertile{
    height: 136px;
}

.lowertile{
    padding-top: 5px;
    height: 56px;
    border-top: #e2e2e2 2px solid;   
}

.flip-horizontally {
  transform: scaleX(-1);
}

.ribbon-holder {
  overflow: hidden;
  position: relative
}
.ribbon {
  position: absolute;
  text-align: center;
  top: 28px;
  width: 182px;
}

.ribbon-right {
  background: red;
  color: white;
  transform: rotate(45deg);
  right: -36px;
}

.ribbon-right2 {
  background: green;
  color: white;
  transform: rotate(45deg);
  right: -30px;
  top: 18px;
  width: 115px;
}
.ribbon-rightOLD {
  background: red;
  color: white;
  transform: rotate(45deg);
  right: -36px;
  box-shadow : 1px 1px 1px black, 0 0 25px red, 0 0 5px darkred;
}

.ribbon-right2OLD {
  background: green;
  color: black;
  transform: rotate(45deg);
  right: -30px;
  top: 18px;
  width: 115px;
  box-shadow : 1px 1px 1px black, 0 0 25px green, 0 0 5px darkgreen;
}

.ribbon-left {
  background: blue;
  color: white;
  transform: rotate(-45deg);
  left: -32px;
  box-shadow : 1px 1px 1px black, 0 0 25px blue, 0 0 5px darkblue;
}

.thumbnail:hover {
    position:relative;
    top:-25px;
    left:-35px;
    width:100%;
    height:auto;
    display:block;
    z-index:999;
}

/* Hiding the checkbox, but allowing it to be focused */
.badgebox
{
    opacity: 0;
}

.badgebox + .badge
{
    /* Move the check mark away when unchecked */
    text-indent: -999999px;
    /* Makes the badge's width stay the same checked and unchecked */
	width: 27px;
}

.badgebox:focus + .badge
{
    /* Set something to make the badge looks focused */
    /* This really depends on the application, in my case it was: */

    /* Adding a light border */
    box-shadow: inset 0px 0px 5px;
    /* Taking the difference out of the padding */
}

.badgebox:checked + .badge
{
    /* Move the check mark back when checked */
	text-indent: 0;
}

.dlk-radio input[type="radio"],
.dlk-radio input[type="checkbox"]
{
	margin-left:-99999px;
	display:none;
}
.dlk-radio input[type="radio"] + .fa ,
.dlk-radio input[type="checkbox"] + .fa {
     opacity:0.15
}
.dlk-radio input[type="radio"]:checked + .fa,
.dlk-radio input[type="checkbox"]:checked + .fa{
    opacity:1
}

.page-break {
   page-break-inside:avoid;
    page-break-after: always;
}
